import React, { ReactNode } from "react";
import ArticleContent from "../../components/ArticleContent/ArticleContent";
import { Col, Row, Wrapper } from "../../components/Grid";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import { ArticleCardFields, ArticleContentFields } from "../../model/common";

interface GeneralContentLayoutProps<
  T,
  A extends ArticleContentFields & T,
  C extends ArticleCardFields & T
> {
  article: A;
  related?: C[];
  relatedTitleId: string;
  renderTags?: (article: T) => ReactNode;
  // Typically Breadcrumbs component
  breadcrumbs: ReactNode;
}

// tslint:disable-next-line: function-name
function GeneralContentLayout<
  T,
  A extends ArticleContentFields & T,
  C extends ArticleCardFields & T
>({
  article,
  related,
  relatedTitleId,
  breadcrumbs
}: GeneralContentLayoutProps<T, A, C>) {
  const { title } = article;
  return (
    <Wrapper>
      <Row justify="center" style={{ marginTop: "30px", marginBottom: "60px" }}>
        <Col sm={10} md={8}>
          <Row justify="between" style={{ marginBottom: "10px" }}>
            <Col>{breadcrumbs}</Col>
            <Col>
              <SocialLinks title={title} />
            </Col>
          </Row>
          <ArticleContent
            titleVariant="h1"
            article={article}
            related={related}
            relatedTitleId={relatedTitleId}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default GeneralContentLayout;
